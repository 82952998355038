import React, { useState } from "react";
import { IProps, TSkinCard } from "./types";
import Label from "@components/containers/home/label";

const SkinCard = (item: TSkinCard) => {
  return (
    <div
      onClick={() => item.onClick()}
      className={`cursor-pointer flex flex-row items-start p-6 ${
        item.selected && "bg-white rounded-lg shadow-lg"
      }`}
    >
      <img alt="RemoteDerm Icon" loading="lazy" src={item.icon} className="h-12 w-12 mr-4" />
      <div className="mt-3 flex flex-col font-sans-pro-regular">
        <div className="flex flex-row items-center mb-1">
          <span className="font-size-24 mr-3">{item.title}</span>
          <img
            alt="Scroll down the page"
            src={"https://cdn.remotederm.ca/icons/footer/arrow-down.webp"}
            className={`w-6 h-6 transform transition-transform duration-500 ${
              item.selected ? "-rotate-180" : "rotate-0"
            }`}
          />
        </div>
        <span className="font-size-16">{item.description}</span>
      </div>
    </div>
  );
};

const Skins = (props: IProps) => {
  const [skinCards, setSkinCards] = useState([
    {
      title: "Skin, and Scalp Check for all Skin Tones and Ages",
      description:
        "Nor matter what your skin tone, your pimples or scalp itch and dandruff are getting worst and can’t wait months to see a dermatologist. You can start your treatment today.",
      icon: "https://cdn.remotederm.ca/icons/home/skin_problem.webp",
      selected: true,
    },
    {
      title: "Pediatric and Young Adult Dermatology",
      description:
        "Our services is not limited to adults. We love your children like our own. If you are a parent and your child has a skin rash, eczema, or acne, or other conditions, our dermatologists will help you to get the right treatment for your child.",
      icon: "https://cdn.remotederm.ca/icons/home/skin_care.webp",
      selected: false,
    },
    {
      title: "Purchasing Skin Care Products Online",
      description:
        "Our dermatologists will guide you to get the right cream, moisturizer, sunscreen, face wash, dandruff shampoo, or pigment correction gel.",
      icon: "https://cdn.remotederm.ca/icons/home/skin_care.webp",
      selected: false,
    },
    {
      title: "Previous Treatments Not Working",
      description:
        "When you have had a treatment in the past for your skin, hair, or nail, and it is not working and you need more help. Our dermatologist will provide you with a customized and an individualized plan based on your skin needs.",
      icon: "https://cdn.remotederm.ca/icons/home/skin_treatment.webp",
      selected: false,
    },
  ]);
  return (
    <div className="px-6 lg:px-16 flex flex-col lg:flex-row items-center mb-16 lg:mb-40">
      <div className="flex lg:flex-1 home-skin-image relative mr-0 lg:mr-8 mb-14 lg:mb-0">
        <img
         width={688}
         height={629}
          loading="lazy"
          title="Achieve Clear, Healthy Skin with the Right Treatment"
          alt="Young black woman with acne scars looking into mirror with insecure appearance"
          src={"https://cdn.remotederm.ca/landing_assets/young-black-woman-with-acne-scars-looking-mirror-insecure-appearance.webp"}
          className={`h-full w-full object-cover absolute left-o top-0 ${
            skinCards[0].selected ? "opacity-1" : "opacity-0"
          }`}
        />
        <img
         width={688}
         height={629}
          loading="lazy"
          src={"https://cdn.remotederm.ca/landing_assets/young-family-with-two-little-boys-medical-reception-young-female-doctor.webp"}
          alt="Close up shot of a smiling woman with pink hair nourishing cream applied to her face like dots"
          className={`h-full w-full object-cover absolute left-o top-0 ${
            skinCards[1].selected ? "opacity-1" : "opacity-0"
          }`}
        />
        <img
         width={688}
         height={629}
          loading="lazy"
          src={"https://cdn.remotederm.ca/landing_assets/close-up-shot-smiling-woman-with-pink-hair-nourishing-cream-applied-her-face-like-dots.webp"}
          alt="Close up shot of a smiling woman with pink hair nourishing cream applied to her face like dots"
          className={`h-full w-full object-cover absolute left-o top-0 ${
            skinCards[2].selected ? "opacity-1" : "opacity-0"
          }`}
        />
        <img
         width={688}
         height={629}
          loading="lazy"
          src={"https://cdn.remotederm.ca/landing_assets/acne-teenage-girl-applying-acne-medication-her-face-front-mirror-care-problem-skin.webp"}
          alt="A teenage girl with acne who is applying acne medication to her face in front of a mirror"
          className={`h-full w-full object-cover absolute left-o top-0 ${
            skinCards[3].selected ? "opacity-1" : "opacity-0"
          }`}
        />
      </div>
      <div className="flex flex-col flex-1 items-center justify-center">
        {skinCards.map((item: TSkinCard, index: number) => (
          <SkinCard
            key={index}
            selected={item.selected}
            title={item.title}
            icon={item.icon}
            description={item.description}
            onClick={() => {
              let items = JSON.parse(JSON.stringify(skinCards));
              items.map((item: TSkinCard) => {
                return (item.selected = false);
              });
              items[index].selected = true;
              setSkinCards(items);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Skins;
