import React from "react";
import { TLabel } from './types'


const Label = (props: TLabel) => {
    return (
        <div className={`${props.dark ? 'bg-color-FFFFFF33' : 'bg-color-efefef'} rounded-md h-6 w-max flex items-center justify-center px-2`}>
            <span className={`${props.dark ? 'color-FFFFFF80' : 'color-636363-secondary-2'} font-sans-pro-italic font-size-14`}>{props.title}</span>
        </div>
    );
};

export default Label;
