import ActionTypes from "@redux/actionsTypes";
import {
  token,
} from "@redux/reducers/auth/types";

export const saveToken = (token: token) => {
  return (dispatch : any) => {
    dispatch({
      type: ActionTypes.auth.SAVE_TOKEN,
      payload: token,
    });
  };
};

