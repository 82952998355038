import React, { useEffect, useRef } from "react";

const options = {
  root: null,
  threshold: 0.15,
  rootMargin: `70px`,
};


const GoToTopHandler = ({ setGoButton }: any) => {
  const containerRef = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    setGoButton(!entry?.isIntersecting);
  };

  return (
    <div ref={containerRef} />
  );
};

export const GoToTopButton = ({ go }: any) => {

  return (
    <div className={`${go ? 'block' : 'hidden'} fixed  left-0 right-0 bottom-12 z-50 flex justify-center items-center`}>
      <div
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="w-10 h-10 animate-bounce flex justify-center items-center cursor-pointer shadow-lg bg-color-c56183-eye-catching rounded-full">
        <img alt="Scroll up icon" className="w-6 h-6" src={'https://cdn.remotederm.ca/icons/home/up-arrow.webp'} />
      </div>
    </div >
  );
};

export default GoToTopHandler;
