import React from "react";
import { IProps } from "./types";
import Label from "@components/containers/home/label";
import { useDispatch } from "react-redux";
import { toggleVideo } from "@redux/actions/video";
import Router from "next/router";
import Params from "@utilities/param";
import { useSearchParams } from "next/navigation";

const Welcome = (props: IProps) => {
  const dispatch = useDispatch();
  return (
    <div className="w-full flex flex-col lg:flex-row mb-20 lg:mb-40">
      <div className="w-full pt-11 lg:pt-24 xl:pt-36 px-6 xl:px-16 ">
        {useSearchParams().get('keyword') != '' &&
                <span className="title-case text-left md:text-center font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1 mb-10 font-sans-pro-semi-bold font-semibold">{useSearchParams().get('keyword')}</span>
        }
        <br/>
        <Label title={"Home"} />
        <h1 className="mt-4 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
          Online Dermatology Consultation in Canada with {" "}
          <span className="font-sans-pro-semi-bold font-semibold">
            RemoteDerm
          </span>{" "}
        </h1>
        <p className="mb-8 pr-0 md:pr-28 font-sans-pro-regular color-636363-secondary-2 font-size-16">
          Fast, Convenient, Affordable & Secure way in Canada to get help online
          for your skin concerns. In the comfort of your home,{" "}
          <span className="font-sans-pro-semi-bold font-semibold">
            Canadian Board Certified Dermatologists
          </span>{" "}
          diagnose your skin, hair or nail conditions. No wait time or doctor
          referral is needed to see a dermatologist.
        </p>
        <div className="flex flex-wrap flex-row items-center">
          <div
            onClick={() => {
              const user = localStorage.getItem(
                Params.LOCAL_STORAGE.PATIENT_INFO
              );
              if (user) {
                window.location.href = process.env.REACT_APP_PATIENT_URL!;
              } else {
                Router.push("/pages/auth?type=login");
              }
            }}
            className="cursor-pointer  mb-5 h-14 flex items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching mr-4"
          >
            {"Get Consultation"}
          </div>
          <div
            onClick={() => toggleVideo()(dispatch)}
            className="cursor-pointer  mb-5 h-14 flex rounded-lg font-sans-pro-regular color-150910-secondary-1 border border-gray-200 items-center justify-center px-8 text-white "
          >
            <img src={"https://cdn.remotederm.ca/icons/home/play.webp"} alt="Watch an introduction video on how RemoteDerm works" className="w-5 h-5 mr-3" />
            {"Watch Intro Video"}
          </div>
        </div>
        <div className="flex flex-row overflow-x-scroll  lg:overflow-hidden lg:flex-col mt-10 lg:mt-24 lg:mb-0 mb-8">
          <div className="flex flex-row items-center mb-0 lg:mb-4">
            <img
            width={296}
            height={106}
              src={"https://cdn.remotederm.ca/icons/home/add.webp"}
              alt="Logo of American Academy of Dermatology Association"
              className="h-20 w-56 xl:h-24 xl:w-72 mr-6"
            />
            <img
            width={296}
            height={106}
              src={"https://cdn.remotederm.ca/icons/home/canadian.webp"}
              alt="Logo of Canadian Dermatology Association"
              className="h-20 w-56 xl:h-24 xl:w-72 lg:mr-0 mr-6"
            />
            <img
            width={296}
            height={106}
              src={"https://cdn.remotederm.ca/icons/home/asds.webp"}
              alt="Logo of American Society for Dermatologic Surgery"
              className="h-20 w-56 flex lg:hidden xl:h-24 xl:w-72 mr-6"
            />
            <img
            width={296}
            height={106}
              src={"https://cdn.remotederm.ca/icons/home/aslms.webp"}
              alt="Logo of American Society for Laser Medicine and Surgery"
              className="h-20 w-56 flex lg:hidden  xl:h-24 xl:w-72 lg:mr-0 mr-6"
            />
          </div>
          <div className="hidden lg:flex flex-row items-center">
            <img
            width={288}
            height={96}
              src={"https://cdn.remotederm.ca/icons/home/asds.webp"}
              alt="Logo of American Society for Dermatologic Surgery"
              className="h-20 w-56 xl:h-24 xl:w-72 mr-6"
            />
            <img
            width={288}
            height={96}
              src={"https://cdn.remotederm.ca/icons/home/aslms.webp"}
              alt="Logo of American Society for Laser Medicine and Surgery"
              className="h-20 w-56 xl:h-24 xl:w-72 lg:mr-0 mr-6"
            />
          </div>
        </div>
      </div>
      <div className="w-5/12 home-welcome-image relative">
        <img
          title="Feeling Confident and Beautiful with Clear Skin"
          alt="Young happy woman with towel head lies in bed with cozy pajamas and a notebook looking up beauty routines"
          className="h-full w-full object-cover"
          width={735}
          height={859}
          src={"https://cdn.remotederm.ca/landing_assets/shot-positive-dark-skinned-young-woman-uses-modern-gadget-networking-chatting.webp"}
        />
        {/* <div className="rounded-lg bg-color-15091080 m-4 flex items-center justify-center font-sans-pro-regular font-size-14 absolute bottom-0 right-0 h-11 px-4 text-white">
                    Photo by <a href={'https://unsplash.com/photos/F16KPYxfm6s'} target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">Jessica Felicio</a>  on <a href="https://unsplash.com" target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">Unsplash</a>
                </div> */}
      </div>
    </div>
  );
};

export default Welcome;
