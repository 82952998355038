import React from "react";
import Welcome from "@components/containers/home/welcome";
import Services from "@components/containers/home/services";
import Skins from "@components/containers/home/skins";
import ContactUs from "@components/containers/home/contactUs";
import AboutUs from "@components/containers/home/aboutUs";
import HowItWorks from "@components/containers/home/howItWorks";
import Specialists from "@components/containers/home/specialists";
import Pricing from "@components/containers/home/pricing";
import Country from "@components/containers/home/country";
import Blogs from "@components/containers/home/blogs";
import Comments from "@components/containers/home/comments";
import * as auth from "@redux/actions/auth";
import { connect } from "react-redux";
import { IProps, IState } from "./types";
import Request, { URLS } from "@api";
import Router from "next/router";
import GoToTopHandler, { GoToTopButton } from "@components/layout/goToTop";
import Head from "next/head";
import Params from "@utilities/param";

const welcome = React.createRef();
const how_it_works = React.createRef();
const services = React.createRef();
const pricing = React.createRef();
const join = React.createRef();
const blogs = React.createRef();
const contact_us = React.createRef();
const about_us = React.createRef();
class Home extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      plans: [],
      blogs: [],
      comments: [],
      go: false,
    };
  }

  componentDidMount() {
    Router.events.on("routeChangeComplete", () => {
      let query = window.location.search as string;
      if (query?.includes("utm_source") && query?.includes("utm_campaign")) {
        localStorage.setItem(Params.LOCAL_STORAGE.GOOGLE_TAGS, query);
      }
      // this.doUrlActions(window.location.search);
    });
    this.getComments();
    this.getPlans();
    this.getBlogs();
  }

  doUrlActions(section: any) {
    if (section) {
      switch (section) {
        case "?section=home":
          // @ts-ignore
          welcome?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=how-it-works":
          // @ts-ignore
          how_it_works?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=services":
          // @ts-ignore
          services?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=pricing":
          // @ts-ignore
          pricing?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=join":
          // @ts-ignore
          join?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=blogs":
          // @ts-ignore
          blogs?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=contact-us":
          // @ts-ignore
          contact_us?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "?section=about-us":
          // @ts-ignore
          about_us?.current?.scrollIntoView({ behavior: "smooth" });
          break;
        default:
        // @ts-ignore
        // welcome?.current?.scrollIntoView({ behavior: "smooth" });
        // break;
      }
    }
  }

  async getComments() {
    const response = (await Request.get(URLS.HOME.comments)) as any;
    if (response) {
      if (response.data.length > 3) {
        this.setState({ comments: response.data });
      } else {
        this.setState({ comments: [...response.data, ...response.data] });
      }
    }
  }

  async getPlans() {
    const response = (await Request.get(URLS.HOME.plans)) as any;
    if (response) {
      this.setState({ plans: response.data });
    }
  }

  async getBlogs() {
    const response = (await Request.get(URLS.HOME.blogs)) as any;
    if (response) {
      this.setState({ blogs: response.data });
    }
  }

  render() {
    return (
      <div className="min-h-screen">
        <Head>
          <meta
            name="facebook-domain-verification"
            content="cmu5owav8skxstlgpjonoth0x4av3e"
          />
          <link rel="canonical" href="https://remotederm.ca" />          
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
                        "@context" : "http://schema.org",
                        "@type" : "LocalBusiness",
                        "name" : "remotederm",
                        "image" : "https://cdn.remotederm.ca/images/logo_text.webp",
                        "logo" : "https://cdn.remotederm.ca/images/logo_text.webp",
                        "telephone" : "+1(877)7546356",
                        "address" : {
                          "@type" : "PostalAddress",
                          "addressRegion" : "Toronto",
                          "addressCountry" : "Canada"
                        },
                        "url" : "/",
                        "priceRange" : "CAD 114.99 - CAD 149.99",
                        "contactPoint": {
                          "@type": "ContactPoint",
                          "contactType": "Customer Support",
                          "telephone" : "+1(877)754-6356",
                          "email":"support@RemoteDerm.ca"
                        },              
                        "sameAs" : [
                          "https://www.instagram.com/remotederm/","https://www.facebook.com/RemoteSkinCare/","https://www.linkedin.com/company/remotederm/"
                        ]
                      }`,
            }}
          />
        </Head>
        <GoToTopHandler setGoButton={(go: boolean) => this.setState({ go })} />
        <div ref={welcome as any} />
        <Welcome />
        <div className="pb-6" ref={services as any} />
        <Services />
        <Skins />
        <div className="pb-6" ref={contact_us as any} />
        <ContactUs />
        <div className="pb-6" ref={about_us as any} />
        <AboutUs />
        <div className="pb-6" ref={how_it_works as any} />
        <HowItWorks />
        <div className="pb-6" ref={join as any} />
        <Specialists />
        <div className="pb-6" ref={pricing as any} />
        <Comments comments={this.state.comments} />
        <Pricing plans={this.state.plans} />
        <Country />
        <div className="pb-6" ref={blogs as any} />
        <Blogs blogs={this.state.blogs} />
        <GoToTopButton go={this.state.go} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  token: state.auth.token,
});

const mapDispatchToProps = {
  saveToken: auth.saveToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
