import React from "react";
import { IProps } from "./types";
import Label from "@components/containers/home/label";
import Router from "next/router";
import Params from "@utilities/param";

const Services = (props: IProps) => {
  return (
    <div className="w-full flex px-6 md:px-0 flex-col justify-start md:justify-center items-start md:items-center">
      <Label title={"Services"} />
      <h2 className="mt-4 mb-5 text-left md:text-center font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
        When to Connect with an Online Dermatologist <br /> in{" "}
        <span className="font-sans-pro-semi-bold font-semibold">
          Canada
        </span>
      </h2>
      <p className="mb-10 text-left md:text-center px-0 md:px-44 xl:px-96 font-sans-pro-regular color-636363-secondary-2 font-size-16">
        Get immediate help for your skin, hair, and nail conditions from the comfort of your
        home in Canada. We connect people with expert dermatologists
        online. People don't have to wait months, and can get personalized care now.
      </p>
      <div
        onClick={() => {
          const user = localStorage.getItem(Params.LOCAL_STORAGE.PATIENT_INFO);
          if (user) {
            window.location.href = process.env.REACT_APP_PATIENT_URL!;
          } else {
            Router.push("/pages/auth?type=login");
          }
        }}
        className="cursor-pointer  mb-20 md:mb-14 h-14 flex items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching"
      >
        {"Get Consultation"}
      </div>
    </div>
  );
};

export default Services;
