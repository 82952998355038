import React from "react";
import { IProps } from "./types";
import Label from "@components/containers/home/label";
import { THowItWorksCards } from "./types";

const HowItWorksCards = (props: THowItWorksCards) => {
  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col">
        <img alt="RemoteDerm Icon" loading="lazy" src={props.icon} className="h-20 w-20 mb-4" width={80} height={80}/>
        <span className="mb-3 font-sans-pro-semi-bold font-semibold font-size-16 color-150910-secondary-1">
          {props.title}
        </span>
        <span className="mb-6 font-sans-pro-regular color-636363-secondary-2 font-size-14">
          {props.description}
        </span>
      </div>
      {/* <div className="mb-8 lg:mb-0 w-max cursor-pointer rounded-lg flex justify-center items-center border-gray-200 border h-12 px-6 font-sans-pro-regular font-size-14 color-150910-secondary-1">
                {'Read More'}
            </div> */}
    </div>
  );
};

const HowItWorks = (props: IProps) => {
  return (
    <div className="flex flex-col mb-20 lg:mb-44 px-6 lg:px-16">
      <Label title={"How it works"} />
      <div className="flex flex-col lg:flex-row mt-2 mb-16">
        <h2 className="mb-5 lg:mb-0 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
          Connect with a dermatologist{" "}
          <br className="hidden lg:inline-block xl:hidden" /> right away{" "}
          <br className="hidden xl:inline-block" /> by following{" "}
          <br className="hidden lg:inline-block  xl:hidden" />{" "}
          <span className="font-sans-pro-semi-bold font-semibold">
            few simple steps
          </span>
        </h2>
        <p className="lg:w-96 lg:mx-auto font-sans-pro-regular color-636363-secondary-2 font-size-16">
          In less than few minutes, you can describe your skin condition, upload
          your image and get connected with a dermatologist. Virtual dermatology
          is the future of skin care. Start your skin care journey right now.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row">
        <HowItWorksCards
          title={"1. Describe your symptoms through our panel"}
          icon={"https://cdn.remotederm.ca/icons/home/appointment_patient.webp"}
          description={
            "Register & answer few important questions regarding your skin concern. One of our dermatologist will review your request and respond shortly."
          }
        />
        <div className="mr-10" />
        <HowItWorksCards
          title={"2. Connected with a dermatologist"}
          icon={"https://cdn.remotederm.ca/icons/home/appointment_chat.webp"}
          description={
            "Next, you’ll be connected with a dermatologist who will diagnosis your skin condition, answer your concerns and prescribe your medications."
          }
        />
        <div className="mr-10" />
        <HowItWorksCards
          title={"3. Medications Get Faxed"}
          icon={"https://cdn.remotederm.ca/icons/home/appointment_prescription.webp"}
          description={
            "The Dermatologist will fax the medication to your local pharmacy. The medication should be ready for pick up the same day"
          }
        />
      </div>
    </div>
  );
};

export default HowItWorks;
